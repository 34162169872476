import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export const getApolloClient = () => {
  if (!apolloClient) {
    throw new Error('apollo client is not initialized');
  }
  return apolloClient;
};

export const setApolloClient = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  if (apolloClient) {
    throw new Error('apollo client is already initialized');
  }
  apolloClient = client;
};

export const resetApolloClient = () => {
  apolloClient = null;
};
